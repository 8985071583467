<template>
  <div>
    <login-comp></login-comp>
  </div>
</template>

<script>
import logincomp from "../components/LoginComp";

export default {
  name: "LoginView",
  components: {
    "login-comp": logincomp,
  },
};
</script>
