<template>
  <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
    <div>
      <v-tabs
        v-model="tab"
        show-arrows
        background-color="deep-purple accent-4"
        icons-and-text
        dark
        grow
      >
        <v-tabs-slider color="purple darken-4"></v-tabs-slider>
        <v-tab v-for="(i, id) in tabs" :key="id">
          <v-icon large>{{ i.icon }}</v-icon>
          <div class="caption py-1">{{ i.name }}</div>
        </v-tab>
        <v-tab-item>
          <v-card class="px-4">
            <v-card-text>
              <v-form ref="loginForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="loginRut"
                      v-mask="'########-N'"
                      mask="########-N"
                      label="Rut-> xx.xxx.xxx-x"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="loginPassword"
                      :append-icon="show1 ? 'eye' : 'eye-off'"
                     
                      :type="show1 ? 'text' : 'password'"
                      autocomplete
                      name="input-10-1"
                      label="Password"
                      hint="igual o mayor a 6 caracteres"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6" xsm="12"></v-col>
                  <v-spacer></v-spacer>
                  <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                    <v-btn
                      x-large
                      block
                      :disabled="!valid"
                      color="success"
                      @click="access"
                      >Login</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="px-4">
            <v-card-text>
              <v-form ref="registerForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="nombre"
                      :rules="[rules.required]"
                      label="Nombre Completo"
                      maxlength="50"
                      dense
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="rut"
                      label="Rut"
                      :rules="[rules.required]"
                      maxlength="20"
                      dense
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="registryNumber"
                      label="N° Registro de Cuerpo"
                      :rules="[rules.required]"
                      maxlength="20"
                      dense
                      required
                    ></v-text-field>
                  </v-col>
                  
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      dense
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show1 ? 'text' : 'password'"
                      autocomplete
                      name="input-10-1"
                      label="Password"
                      hint="igual o mayor a 6 caracteres"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      block
                      v-model="verify"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, passwordMatch]"
                      :type="show1 ? 'text' : 'password'"
                      autocomplete
                      name="input-10-1"
                      label="Confirm Password"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                    <v-btn
                      x-large
                      block
                      :disabled="!valid"
                      color="success"
                      @click="crearNuevoUsuario"
                      >Registrar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
  </v-dialog>
</template>

<script>
import axios from "axios";
import  AuthService from "../services/AuthService.js"
export default {
  name: "login-comp",

  data: () => ({
    dialog: true,
    tab: 0,
    tabs: [
      { name: "Login", icon: "mdi-account" },
      // { name: "Register", icon: "mdi-account-outline" },
    ],
    valid: true,

    nombre: "",
    email: "",
    password: "",
    verify: "",
    registryNumber:"",
    loginPassword: "",
    loginRut: "",
    loginEmailRules: [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    emailRules: [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],

    show1: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => (v && v.length >= 6) || "Min 6 caracteres",
    },
  }),
  methods: {

    access(){
      AuthService.login(this.loginRut,this.loginPassword).then((response)=>{
        AuthService.setAuthHeader()
        console.log(response.data);
        this.$store.commit("setDataLoginUser", response.data);
        this.$router.replace("principal");
      })
      
    },
   
    crearNuevoUsuario: function () {
      if (this.$refs.registerForm.validate()) {
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/signup`, { 
          "name": this.nombre,
          "email": this.email,
          "rut": this.rut,
          "password": this.password,
          "registryNumber": this.registryNumber
        }).then((response)=>{
          const datos = response.status
          if(datos == 201){
            this.$swal("Se creo el usuario correctamente");
            this.tab = 0
          }
        })

      }
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Contraseñas no coinciden";
    },
  },
  created() {},
};
</script>
